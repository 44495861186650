import { useCurrentSessionContext } from '@/context/currentSessionContext';
import { IconButton } from '@mui/material';
import { useSession } from 'next-auth/react';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import CloseIcon from "@mui/icons-material/Close";

const SnackbarAction: React.FC<{ key: SnackbarKey }> = ({ key }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar(key)}
    >
      <CloseIcon sx={{ height: 20, width: 20 }} />
    </IconButton>
  );
};
export function CustomSnackbarProvider({ children }: { children: React.ReactNode }) {
    const { data } = useSession();
    const { session } = useCurrentSessionContext();
    const activeAuth = process.env.NEXT_PUBLIC_ACTIVATE_AUTH === 'true';
  if (activeAuth && (!data || !session)) {
    return <>{children}</>;
  }

  return (
    <SnackbarProvider maxSnack={3}
    autoHideDuration={null}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    action={(key: SnackbarKey) => <SnackbarAction key={key} />}>
      {children}
    </SnackbarProvider>
  );
} 